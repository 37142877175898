import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Layout from '../common/Layout'
import SearchBox from '../common/SearchBox'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles'

import { fetchDatas } from '../../actions/fetch.actions'
import { API_URL } from '../../config'
import QueriesList from '../common/QueriesList'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { history } from '../../helpers'
import { fetchDatasWithName } from '../../actions/fetchWithName.actions'



const styles = theme => ({
  appbar: {
    backgroundColor: 'white'
  },
  tab: {
    color: 'black'
  }
})

class PageSearch extends Component {

  tabs = [
    {
      uri: '/all-queries',
      label: 'Nouvelles demandes',
      type: 'new',
    },
    {
      uri: '/in-progress-queries',
      label: 'Demandes en cours',
      type: 'current',
    },
    {
      uri: '/ended-queries',
      label: 'Demandes terminées',
      type: 'ended',
    },
    {
      uri: '/canceled-queries',
      label: 'Demandes annulées',
      type: 'canceled',
    }
  ]



  currentTab = this.tabs[0];

  // at component init, detect url, select tab and load queries
  componentWillMount() {
    const uri = history.location.pathname;
    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i]
      if (tab.uri === uri) {
        this.currentTab = tab;
        return this.loadQueries(tab.type);
      }
    }
  }

  // load queries for a selected tab
  loadQueries = (type) => {
    this.props.dispatch(
        fetchDatas(API_URL + '/inquery/inquerypanel.php?type=' + type, 'current', 'POST')
    )
  }

  // handle tab change
  handleChange = (event, tab) => {
    this.props.history.push(tab.uri)
  }

  render() {
    const { classes } = this.props


    // build tabs to display
    const tabs = [];
    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i]
      tabs.push(<Tab value={tab} label={tab.label} className={classes.tab} />)

    }

    return (
      <div>
        <Layout>
          {/* <Grid container spacing={24}>
            <Grid item xs={12} md={12}> */}
              {/* search screen specific navigation */}
              <AppBar className={classes.appbar} position="static">
                <Tabs value={this.currentTab} onChange={this.handleChange}>
                  {tabs}
                </Tabs>
              </AppBar>
              <SearchBox />
              <QueriesList />
    
            {/* </Grid>
          </Grid> */}
        </Layout>

      </div>
    )
  }
}


export default withStyles(styles)(connect()(PageSearch))
