import {IS_CHECKED} from '../actions/proListCheckBox.actions';

const InitialState = {pro: []};

export default function proListCheckBoxReducer(state = InitialState, action) {
    switch (action.type) {
        case IS_CHECKED:
            action.proChecked === null ? state.pro.splice(-1, 1) : state.pro = [...state.pro, action.proChecked];
            return {
                ...state,
                pro: [...state.pro]
            };
        default:
            return state;
    }
}