import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { searchPro } from '../../actions/searchBar.actions'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from 'semantic-ui-react/dist/commonjs/modules/Search'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap'
  },
  wrapper: {
    minWidth: '100%',
    marginTop: 24
  },
  paper: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2
  },
  searchbar: {
    margin: theme.spacing.unit,
    padding: 5,
    width: '90%'
  },
  selectBox: {
    width: 90
  },
  checkbox: {
    marginLeft: 50
  },

  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
})

class SearchBoxPagePro extends Component {

  constructor(props) {
    super(props)

    this.state = {
      radius: 0,
      activity: '',
      location: '',
      proDatas: '',
      data: ''
    }

    this.onChange = this.onChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  search = (e) => {
    this.props.getSearchValue(e.target.value)
  }

  onChange(value, key) {
    this.setState({[key]: value})
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const {classes, loading, error} = this.props

    if (error) {
      return (<div>Error! {error.message} </div>)
    }

    if (loading) {
      return <div>Loading ...</div>
    }

    return (
      <div className={classes.wrapper}>
        <Paper>
          <div className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <div>
                  <TextField
                    id="standard-textarea"
                    type="search"
                    placeholder='Recherche'
                    value={this.props.searchedValue}
                    onChange={this.search}
                    className={classes.searchbar}
                    margin="normal"
                    startAdornment={
                      <InputAdornment position="start">
                        <Search/>
                      </InputAdornment>
                    }
                  />
                  <Grid/>

                </div>

              </Grid>

            </Grid>
          </div>
        </Paper>

      </div>
    )
  }
}

SearchBoxPagePro.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  searchedValue: state.searchReducer.search,
  proDatas: state.datasReducer.proDatas,
  oneQuerie: state.datasReducer.oneQuerie,
  loading: state.datasReducer.loading,
  error: state.datasReducer.error,
  types: state.datasReducer.types,
  data: state.postDatasReducer.datas
})

const mapDispatchToProps = (dispatch) => ({
  getSearchValue: bindActionCreators(searchPro, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchBoxPagePro))
