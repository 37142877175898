import React, { Component } from 'react';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import BuildIcon from '@material-ui/icons/Build';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Parking from '../../images/Parking_opt.png';
import Insurance from '../../images/Insurance_opt.png';
import Repairer from '../../images/Repairer_opt.png';


class IconsRuler extends Component {

    constructor(props) {
        super(props)
    }

    selectIcon(data) {
        if (data == 1 || data == 2) {
            return (
                <img src={Repairer} width="50px" height="50px" />)

        } else if (data == (3)) {
            return (<img src={Parking} width="50px" height="50px" />)

        } else if (data == (4)) {
            return (<img src={Insurance} width="50px" height="50px" />)

        }
    }

    render() {
        return (
            <div>
                {this.selectIcon(this.props.data)}
            </div>
        )
    }
}

export default IconsRuler;