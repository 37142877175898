import React, { Component } from 'react'
import Layout from '../common/Layout'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import classNames from 'classnames';

import QueriesList from '../common/QueriesList'
import { API_URL } from '../../config'
import { connect } from 'react-redux'
import { fetchDatas } from '../../actions/fetch.actions'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    'overflow-x': 'auto'
  },
  showMoreButton: {
    margin: '5%',
    width: '20%',
    borderRadius: '120px'
  },
  tablecellmd: {
    width: '60 px',
    padding: '0 5px 0 5px'
  },
  tablecellsm: {
    width: 15,
    padding: 0
  },
})

class HomePage extends Component {

  // load 5 last requests from API
  componentWillMount() {
    this.props.dispatch(
      fetchDatas(API_URL + '/inquery/inquerypanel.php?type=recent&limit=5', 'current', 'POST')
    )
  }

  render() {
    return (
      <Layout>
        <Typography>Dernières demandes reçues</Typography>
        <QueriesList/>
        <TableCell>
                <Link to="/all-queries"><Button variant="contained" color="secondary"
                 >Voir toutes les
                  demandes</Button></Link>
              </TableCell>
      </Layout>
    )
  }
}

export default connect()(HomePage)
