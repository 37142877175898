import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { lighten } from '@material-ui/core/styles/colorManipulator'

import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Create from '@material-ui/icons/Create'
import Badge from '@material-ui/core/Badge'
import PriorityModal from './PriorityModal'
import Moment from 'react-moment'
import IconsRuler from './IconsRuler'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ProfessionalsTab from './ProfessionalsTab';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    'overflow-x': 'auto'
  },
  showMoreButton: {
    margin: '5%',
    width: '20%',
    borderRadius: '120px'
  },
  userRow: {
    width: '100%',
    display: 'flex',
    flexshrink: '0',
    flexGrow: '1'
  },
  tablecellmd: {
    width: '60 px',
    padding: '0 5px 0 5px',
    textAlign: 'center'
  },
  tablecellsm: {
    width: 15,
    padding: 0,
    textAlign: 'center'
  },
  midPrioButton: {
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700]
    },
    fontSize: '0.7500rem'
  },
  avatarButton: {
    width: 125,
    height: 125,
    marginRight: 10
  },
  avatarSize: {
    width: 120,
    height: 120,
    fontSize: '0.870rem'
  },

})

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  actions: {
    color: theme.palette.text.secondary
  }
})

const columnData = [
  {id: 'Country', numeric: false, disablePadding: false, label: 'pays'},
  {id: 'LasterName', numeric: false, disablePadding: false, label: 'client'},
  {id: 'InqueryTypeID', numeric: false, disablePadding: false, label: 'type'},
  {id: 'EmailAddress', numeric: false, disablePadding: false, label: 'email'},
  {id: 'PhoneNumber', numeric: false, disablePadding: false, label: 'telephone'},
  {id: 'BoatName', numeric: false, disablePadding: false, label: 'bateau'},
  {id: 'Title', numeric: false, disablePadding: false, label: 'message'},
  {id: 'InquerySubmissionDate', numeric: false, disablePadding: false, label: 'date'},
  {id: 'InqueryID', numeric: false, disablePadding: false, label: 'échéance'},
  {id: 'montant', numeric: false, disablePadding: false, label: 'montant'},
  {id: 'details', numeric: false, disablePadding: false, label: 'détails'}
]
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {order, orderBy} = this.props;

    return (

        <TableHead>
              <TableRow>
       <TableCell paddingRight='checkbox'>
         </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}>
                <Tooltip
                  title="Tri"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}>
                    {column.label}
                  </TableSortLabel>
                  </Tooltip>
              </TableCell>

            )
          }, this)}
          </TableRow>       

        </TableHead>

    )   
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

class QueriesList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      page: 0,
      rowsPerPage: 25,
      ids: []
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

isSelected = id => this.state.selected.indexOf(id) !== -1;

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  // Ce qui suit doit avoir un lien avec le fait que le tri ne marche pas sur cette page. Le "SellerPro" l.242
  // et "AverageRating" l.248 ont été copiés depuis le composant "PageAllParkings.jsx" et doivent donc être adaptés ici.

  desc(a, b, orderBy) {
    if (a[orderBy])
      a[orderBy] = a[orderBy].toLowerCase()
    if (b[orderBy])
      b[orderBy] = b[orderBy].toLowerCase()
    if (orderBy === 'SellerPro') {
      if (a[orderBy] && !b[orderBy])
        return -1
      else
        return 1
    }
    if (orderBy === 'AverageRating') {
      if (b[orderBy].Note < a[orderBy].Note)
        return -1
      if (b[orderBy].Note > a[orderBy].Note)
        return 1
      return 0
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy)
  }

  render() {
    const { classes, error, loading, current, searchedValue } = this.props;
    const { order, orderBy, selected, page, rowsPerPage} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, current && current.length - page * rowsPerPage);

    if (error) {
      return <div>Error! {error.message}</div>
    }
    if (loading) {
      return <div>Loading...</div>
    }

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length}/>
        <div className={classes.tableWrapper}>

        <Table className={classes.table} aria-labelledby="tableTitle">
        <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={current && current.length}/>

{current && this.stableSort(current, this.getSorting(order, orderBy))
              .filter(
              query => (
                (query.Country ? query.Country.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.FirstName ? query.FirstName.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.LasterName ? query.LasterName.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.InqueryTypeID ? query.InqueryTypeID.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.EmailAddress ? query.EmailAddress.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.PhoneNumber ? query.PhoneNumber.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.BoatName ? query.BoatName.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.Title ? query.Title.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.InquerySubmissionDate ? query.InquerySubmissionDate.match(new RegExp(searchedValue, 'i')) : '') ||
                (query.InqueryID ? query.InqueryID.match(new RegExp(searchedValue, 'i')) : '')
              ))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n, i) => {
                const isSelected = this.isSelected(n.QueryID)
                return (

                  // Ce qui suit en commentaires est un composant de Material UI et permet de générer l'ouverture d'un sous panneau
                  // (celui où on va afficher les réponses des professionnels à une demande entrante)
                  // mais ça provoque une instabilité au niveau de l'affichage des lignes de la page...  

                  // <ExpansionPanel>
                  // <ExpansionPanelSummary>
                  
                  <TableRow
                    hover
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={i}
                    selected={isSelected}>
                     
                    <TableCell  padding="checkbox"></TableCell>
                    <TableCell scope="row" padding="none">{n.Country}</TableCell>
                    <TableCell>{n.FirstName + ' ' + n.LasterName}</TableCell>
                    <TableCell><IconsRuler data={n.InqueryTypeID} /></TableCell>
                    <TableCell>{n.EmailAddress}</TableCell>
                    <TableCell>{n.PhoneNumber}</TableCell>
                    <TableCell>{n.BoatName}</TableCell>
                    <TableCell>{n.Title}</TableCell>
                    <TableCell><Moment format="DD/MM/YYYY">{n.InquerySubmissionDate}</Moment></TableCell>
                    <TableCell>
                          <Badge color='primary'>
                            <PriorityModal InqueryID={n.InqueryID} priority={n.Priority} />
                          </Badge>
                    </TableCell>
                    <TableCell>En attente du back</TableCell>
                    <TableCell>
                          <IconButton component={Link} to={'/queries/' + n.InqueryID}>
                          <Create color='primary'/>
                          </IconButton>
                    </TableCell>
                    </TableRow>

                    // </ExpansionPanelSummary>


                    // <ExpansionPanelDetails>
                    //   <ProfessionalsTab />
                    // </ExpansionPanelDetails>


                    // </ExpansionPanel>

                )
              })}

              <TableBody>
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>
                  <TableCell colSpan={11}/>
                </TableRow>
              )}
             </TableBody>
        
        </Table>
        </div>

        <TablePagination
                    component="div"
                    count={current && current.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                        'color': 'inherit',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                        'color': 'inherit',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

        </Paper>
    )
  }
}

QueriesList.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return ({
    loading: state.datasReducer.loading,
    error: state.datasReducer.error,
    current: state.datasReducer.current,
    searchedValue: state.searchReducer.search
  })
}

export default withStyles(styles)(connect(mapStateToProps)(QueriesList))