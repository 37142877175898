import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import SearchBox from '../common/SearchBox';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withStyles} from '@material-ui/core/styles';

import ProDetails from './ProDetails';
import ProComment from './ProComment';

const styles = theme => ({
  appbar: {
    backgroundColor: 'white'
  },
  tab:{
    color: 'black'
  }
})

class ProTopPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Layout>
          <Grid container spacing={24}>
            <Grid item xs={12} md={12}>

              <AppBar className={classes.appbar} position="static">
              <Tabs value={this.state.value} onChange={this.handleChange}>
              <Tab className={classes.tab} label="Details"/>
              <Tab className={classes.tab} label="Commentaires Reçu"/>
              </Tabs>
              </AppBar>
              {this.state.value === 0 && <ProDetails CompanyID={this.props.match.params.id}/>}
              {this.state.value === 1 && <ProComment CompanyID={this.props.match.params.id}/>}
            </Grid>
          </Grid>
        </Layout>
      </div>
    )
  }
}

export default withStyles(styles)(ProTopPage);
