import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Moment from 'react-moment';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { API_URL } from "../../config";

const styles = () => ({
    profileUser: {
        width: '75%',
        border: '1px solid #c4c4c4',
        height: '220px',
        marginLeft: '20px',
        float: 'right'
    },
    right: {
        float: 'right',
        marginRight: '10px',
        marginTop: '10px'
    },
    cardUser: {
        minWidth: 'max-content',
        height: '400px',
    },
    cardMessage: {
        width: 'auto',
        height: '400px',
    },
    left: {
        float: 'left',
        marginLeft: '10px',
        marginTop: '10px'
    },
    detail: {
        marginLeft: '10px',
        fontWeight: '500',
        paddingBottom: '10px',
        fontSize: '20px',
    },
    insurance: {
        marginLeft: '15px'
    },
    middle: {
        marginLeft: '350px',
    },
    images: {
        float: 'right',
        marginTop: '10px',
        marginRight: '10px'
    },
    titleDemande: {
        fontWeight: '500',
        marginLeft: '20px',
        fontSize: '20px'
    },
    textDemande: {
        marginLeft: '20px'
    },
    image: {
        display: 'flex',
        flexDirection: 'row',
    },
    divMessage: {
        paddingLeft: '15px',
        width: '-webkit-fill-available',
    },
    flex: {
        display: 'flex',
        justifyContent: 'flex-start'
    }
});

class ProfileInquery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Open: false,
            photoSelected: 0
        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange=this.onChange.bind(this);
        this.handleQuerie = this.handleQuerie.bind(this);
    }

    componentWillMount() {
        const data = new FormData();
        data.append("inqueryid", this.props.id);
        this.props.dispatch(fetchDatasWithName(API_URL + '/thisinquerypanel.php', 'inqueryInfo', 'POST', data));
    }

    handleClickOpen(value) {
        this.setState({
            Open: true,
            photoSelected:value
        })
    }

    onChange(value, key) {
        this.setState({[key]: value});
    };

    handleClose() {
        this.setState({Open: false})
    }

    handleQuerie(type, inquery) {
        if (type === '4') {
            return (
                <div>
                    <Typography className={this.props.classes.detail}>Assurance</Typography>
                    <Typography className={this.props.classes.insurance}><label>Assureur actuel : </label> {inquery.ActualInsurance}</Typography>
                    <Typography className={this.props.classes.insurance}><label>Date de fin de contrat : </label> <Moment format="DD/MM/YYYY">{inquery.DateEndContract}</Moment></Typography>
                    <Typography className={this.props.classes.insurance}><label>Prime : </label> {inquery.CurrentPrice} €</Typography>
                    <Typography className={this.props.classes.insurance}><label>Valeur du bateau : </label> {inquery.InsureGood} €</Typography>
                    <Typography className={this.props.classes.insurance}><label>Commentaire :</label> {inquery.CommentInqueries}</Typography>
                      <Typography className={this.props.classes.detail}>Date de la demande</Typography>
                      <Typography className={this.props.classes.insurance}> <Moment format="DD/MM/YYYY" add={{weeks: inquery.Priority}}>{inquery.InquerySubmissionDate}</Moment></Typography>
                </div>
            );
        } else if (type === '1' || type === '2') {
            return (
                <div>
                    <Typography className={this.props.classes.detail}>Titre</Typography>
                    <Typography className={this.props.classes.insurance}>{inquery.Title}</Typography>
                    <Typography className={this.props.classes.detail}>Message</Typography>
                    <Typography className={this.props.classes.insurance}>{inquery.Message}</Typography>
                    <Typography className={this.props.classes.detail}>Date de la demande</Typography>
                    <Typography className={this.props.classes.insurance}> <Moment format="DD/MM/YYYY" add={{weeks: inquery.Priority}}>{inquery.InquerySubmissionDate}</Moment></Typography>
                </div>
            );
        } else if (type === "3") {
            return (
                <div>
                  <Typography className={this.props.classes.detail}>Date de la demande</Typography>
                  <Typography className={this.props.classes.insurance}> <Moment format="DD/MM/YYYY" add={{weeks: inquery.Priority}}>{inquery.InquerySubmissionDate}</Moment></Typography>
                    <Typography className={this.props.classes.detail}>Période demandé</Typography>
                    <Typography className={this.props.classes.insurance}><label>du</label> <Moment format="DD/MM/YYYY">{inquery.OrderStartDate}</Moment> <label>au</label> <Moment format="DD/MM/YYYY">{inquery.OrderEndDate}</Moment></Typography>
                </div>
            );
        }
    }

    render() {
        const {classes, inqueryInfo} = this.props;

        if (inqueryInfo) {
            localStorage.setItem('inqueryInfo', JSON.stringify(inqueryInfo));
        }
        return (
            <div className={classes.flex}>
                <Card className={classes.cardUser}>
                    <CardContent className={classes.cardUser}>
                        <div className={classes.left}>
                            <Typography className={classes.detail}>Détails du bateau</Typography>
                            <Typography className={classes.boat}>Type : {this.props.inqueryInfo && this.props.inqueryInfo.BoatType}</Typography>
                            <Typography className={classes.boat}>Nom du Bateau : {this.props.inqueryInfo && this.props.inqueryInfo.BoatName}</Typography>
                            <Typography className={classes.boat}>Marque : {this.props.inqueryInfo && this.props.inqueryInfo.Maker} - {this.props.inqueryInfo && this.props.inqueryInfo.BoatModel} ({this.props.inqueryInfo && this.props.inqueryInfo.BoatYear})</Typography>
                            <Typography className={classes.boat}>Moteur : {this.props.inqueryInfo && this.props.inqueryInfo.Engine} ({this.props.inqueryInfo && this.props.inqueryInfo.EngineYear})</Typography>
                            <Typography className={classes.boat}>Puissance : {this.props.inqueryInfo && this.props.inqueryInfo.EnginePower} cv</Typography>
                            <Typography className={classes.boat}>Numéro de serie du moteur : {this.props.inqueryInfo && this.props.inqueryInfo.EngineSerialNumber}</Typography>
                            <Typography className={classes.boat}>Immatriculation du bateau : {this.props.inqueryInfo && this.props.inqueryInfo.BoatSerialNumber}</Typography>
                            <Typography className={classes.address}>Port d'attache : {this.props.inqueryInfo && this.props.inqueryInfo.FullAddressBoat}</Typography>
                            {this.props.inqueryInfo && this.props.inqueryInfo.BoatPicture && this.props.inqueryInfo.BoatPicture.map((n, i) => {
                                return (
                                    <div key={i}>
                                        <Button onClick={() => {this.handleClickOpen(i)}}>
                                        <img width="60" height="60" alt="boat pictures"
                                                src={API_URL + '/boatpictures/' + n.PictureURL}/></Button>
                                        <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                                            <DialogContent>
                                                <img width="500" height="500" alt="boat pictures" src={API_URL + '/boatpictures/' + this.props.inqueryInfo.BoatPicture[this.state.photoSelected].PictureURL}/>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                )
                            })}
                        </div>
                    </CardContent>
                </Card>

                <div className={classes.divMessage}>
                    <Card className={classes.cardMessage}>
                        <CardContent>
                            {this.props.inqueryInfo && this.handleQuerie(this.props.inqueryInfo.InqueryTypeID, this.props.inqueryInfo)}
                            <div className={classes.image}>
                                {this.props.inqueryInfo && this.props.inqueryInfo.pictures && this.props.inqueryInfo.pictures.map((n, i) => {
                                    return (
                                        <div key={i}>
                                            <Button onClick={() => {this.handleClickOpen(i)}}>
                                            <img width="60" height="60" alt="inquery pictures"
                                                    src={API_URL + '/inquerypictures/' + n.PictureURL}/></Button>
                                            <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                                                <DialogContent>
                                                    <img width="500" height="500" alt="inquery pictures" src={API_URL + '/inquerypictures/' + this.props.inqueryInfo.pictures[this.state.photoSelected].PictureURL}/>
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    )
                                })}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        inqueryInfo: state.datasReducer.inqueryInfo,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
    });
}

export default withStyles(styles)(connect(mapStateToProps)(ProfileInquery));
