export default theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      display: 'flex',
      padding: 10,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
      }
    },
    textField: {
        marginLeft: '2%',
        marginRight: '2%',
        marginTop: '5%',
        width: '96%'
    },
    textFieldContent: {
        marginLeft: '2%',
        marginRight: '2%',
        marginTop: '5%',
        marginBottom: '10%',
        width: '96%'
    },
    cardName: {
        marginRight: '10px',
        width: '25%',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    cardAddress: {
        marginRight: '10px',
        width: '25%',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    cardMessage: {
        width: '50%',
    },
    titles: {
        textAlign: 'center',
        fontSize: '20px',
        borderBottom: '1px solid #C1C1C1'
    },
    buttons: {
        width: '80%',
        margin: 'auto',
        display: 'flex', 
        flexDirection: 'space-between'
    },
    buttonCancel: {
        marginLeft: '2%',
        marginRight: 'auto',
        width: '45%',
        fontSize: '16px'
    },
    buttonValidate: {
        marginRight: '2%',
        marginLeft: 'auto',
        width: '45%',
        fontSize: '16px'
    }
});


//Champs à éditer : 
    // CompanyName
    // PrenomRepresentantLegal
    // NomRepresentantLegal
    // NumeroVoieAdresse
    // TypeVoieAdresse
    // LibelleVoieAdresse
    // ComplementAdresse
    // Ville
    // Code_Postal
    // ContentMessage
