import { API_URL } from "../config";

export const userService = {
    login,
    logout,
    signin
};

function login(username, password) {

    var formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return fetch(API_URL + '/adminuserlogin.php', {
        method: 'POST',
        headers: {},
        body: formData
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('username', username);
            return user;
        });
}


function signin(username, email, password) {
    var data = new FormData();
    data.append("username", username);
    data.append("password", password);
    data.append("email", email);
    return fetch(API_URL + '/adminusercreate.php', {
        method: 'POST',
        headers: {},
        body: data
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(user => {

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('username', username);
            return user;
        });
}

function logout() {
    localStorage.removeItem('user');
    localStorage.clear();
}
