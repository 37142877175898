import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Layout from '../common/Layout';
import ParkingDescription from './ParkingDescription';
import ParkingComment from './ParkingComment';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = () => ({
  appbar: {
    backgroundColor: 'white'
  },
  tab:{
    color: 'black'
  }
});

class ParkingDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {loading, error, classes} = this.props;

    if (error) {
      return <div>Error! {error.message}</div>;
    }
    if (loading) {
      return <div>Loading ...</div>;
    }

    return (
      <div>
      <Layout>
      <Grid container spacing={24}>
      <Grid item xs={12} md={12}>

      <AppBar className={classes.appbar} position="static">
      <Tabs value={this.state.value} onChange={this.handleChange}>
      <Tab className={classes.tab} label="Details du parking"/>
      <Tab className={classes.tab} label="Commentaires Reçu"/>
      </Tabs>
      </AppBar>
      {this.state.value === 0 && <ParkingDescription id={this.props.match.params.id}/>}
      {this.state.value === 1 && <ParkingComment id={this.props.match.params.id}/>}
      </Grid>
      </Grid>
      </Layout>
      </div>
    );
  }
}

export default withStyles(styles)(ParkingDetails);
