import React, {Component} from 'react';
import ProListINSEE from './ProListINSEE';
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import SearchBoxPagePro from '../common/SearchBox'
import SearchBox from "../Users/PageSearchUser";


class PageSearchINSEE extends Component {

    render() {
      console.log(this.props)
        return (
            <div>
                <Layout>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={12}>
                            <ProListINSEE/>
                        </Grid>
                    </Grid>
                </Layout>
            </div>
        )
    }
}

export default PageSearchINSEE;
