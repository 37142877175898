export function postDatas(url, postDatas) {
    let local = JSON.parse(localStorage.getItem('user'));

    const option = {
        method: 'POST',
        headers: {
            'X-ACCESS-TOKEN': local.user.accesstoken,
        },
        body: postDatas,
    };
    return dispatch => {
        return fetch(url, option)
            .then(res => res.json())
            .then(json => {
                dispatch(postDatasSuccess(json));
                return json;
            })
            .catch(error => dispatch(postDatasError(error)))
    }
}

export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const POST_DATA_ERROR = 'POST_DATA_ERROR';

export const postDatasSuccess = (payload) => {
    return {
        type: POST_DATA_SUCCESS,
        payload
    }
};
export const postDatasError = (error) => {
    return {
        type: POST_DATA_ERROR,
        error
    }
};
