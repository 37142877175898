import {FETCH_DATAS_BEGIN, FETCH_DATAS_ERROR, FETCH_DATAS_SUCCESS} from '../actions/fetch.actions';

const initialState = {
    datas: [],
    loading: false,
    error: null,
    type: null,
    new: [],
    current: [],
    newinsurance: [],
    types: [],
    allNewQueriesData: [],
    allCurrentQueriesData: [],
    allNewInsuranceDatas: [],
    chat: [],
    messages: [],
    parkingCalender: []
};

export default function datasReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_DATAS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_DATAS_SUCCESS:
            let newstate = {
                ...state,
                loading: false,
            };
            newstate[action.url] = action.payload.datas;
            return newstate;

        case FETCH_DATAS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                datas: []
            };

        default:
            return state;
    }
}
