import React, {Component} from 'react'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import {connect} from "react-redux";
import {postDatas} from "../../actions/postData.actions";
import { API_URL } from '../../config'

const styles = theme => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        textAlign: 'center'
    },
    hidden: {
        visibility: 'visible'
    },
    NormalPrioButton: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    },
    midPrioButton: {
        color: theme.palette.getContrastText(yellow[600]),
        backgroundColor: yellow[600],
        '&:hover': {
            backgroundColor: yellow[800]
        },
    },
    HighButton: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700]
        },
    }
});

class PriorityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            title: "",
            priorityLevel: this.props.priority,
        };

        this.changeToNormal = this.changeToNormal.bind(this);
        this.changeToLow = this.changeToLow.bind(this);
        this.changeToMid = this.changeToMid.bind(this);
        this.changeToHigh = this.changeToHigh.bind(this)
    };

    componentWillReceiveProps = props => {
        if (props.priority !== this.props.priority) {
            this.setState({priorityLevel: props.priorityLevel});

        }
    };

    handleOpen = () => {
        this.setState({open: true})
    };

    handleClose = () => {
        this.setState({open: false});
    };

    changeToLow = () => {
        const data = new FormData();

        data.append('inqueryid', this.props.InqueryID);
        this.setState({priorityLevel: "4"}, () => {
            data.append('priority', this.state.priorityLevel);
            this.props.dispatch(postDatas(API_URL + 'inquerychange.php', data));
        });

    };

    changeToNormal() {
        const data = new FormData();

        data.append('inqueryid', this.props.InqueryID);
        this.setState({priorityLevel: "3"}, () => {
            data.append('priority', this.state.priorityLevel);
            this.props.dispatch(postDatas(API_URL + 'inquerychange.php', data));
        });
    };

    changeToMid = () => {
        const data = new FormData();

        data.append('inqueryid', this.props.InqueryID);
        this.setState({priorityLevel: "2"}, () => {
            data.append('priority', this.state.priorityLevel);
            this.props.dispatch(postDatas(API_URL + 'inquerychange.php', data));
        });
    };

    changeToHigh = () => {
        const data = new FormData();

        data.append('inqueryid', this.props.InqueryID);
        this.setState({priorityLevel: "1"}, () => {
            data.append('priority', this.state.priorityLevel);
            this.props.dispatch(postDatas(API_URL + 'inquerychange.php', data));
        });
    };

    onChange(value, key) {
        this.setState({[key]: value});
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                {this.state.priorityLevel === "1" ?
                    <Button classes={{containedPrimary: classes.HighButton}} onClick={this.handleOpen}
                            variant="contained" color="primary">haute</Button> :
                    this.state.priorityLevel === "2" ?
                        <Button classes={{containedPrimary: classes.midPrioButton}} onClick={this.handleOpen}
                                variant="contained" color="primary"
                        >moyenne</Button> :
                        this.state.priorityLevel === "4" ?
                            <Button onClick={this.handleOpen} variant="contained" color="primary">basse</Button> :
                            this.state.priorityLevel === "3" ?
                                <Button classes={{containedPrimary: classes.NormalPrioButton}} onClick={this.handleOpen}
                                        variant="contained" color="primary">normale</Button> : ''}

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    classes={{hidden: classes.hidden}}
                >
                    <div className={classes.paper}>
                        <p>Cliquez sur un bouton pour attribuer une priorité</p>
                        <Grid container spacing={24}>

                            <Grid item xs={12} md={3}>
                                <form><Button className={classes.PriorityButton} variant="contained" color="primary"
                                              onClick={this.changeToLow}>BASSE</Button></form>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button classes={{containedPrimary: classes.NormalPrioButton}} variant="contained"
                                        color="primary" onClick={this.changeToNormal}>NORMALE</Button>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button classes={{containedPrimary: classes.midPrioButton}} variant="contained"
                                        color="primary" onClick={this.changeToMid}>MOYENNE</Button>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button classes={{containedPrimary: classes.HighButton}} variant="contained"
                                        color="primary" onClick={this.changeToHigh}>HAUTE</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return ({
        oneQuerie: state.datasReducer.oneQuerie,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
    })
}

export default withStyles(styles)(connect(mapStateToProps)(PriorityModal));
