import {SEARCH_PRO} from '../actions/searchBar.actions';

const InitialState = {search: ''};

export default function searchReducer(state = InitialState, action) {
    switch (action.type) {
        case SEARCH_PRO:
            return {
                ...state,
                search: action.searchValue
            };
        default:
            return state;
    }
}
