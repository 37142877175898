import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrivateRoute } from './components/common/PrivateRoute';
import HomePage from './components/HomePage/HomePage';
import { LoginPage } from './components/LoginPage/LoginPage';
import { SigninPage } from './components/SigninPage/SigninPage';
import PageSearch from './components/PagePro/PageSearch';
import PageSearchINSEE from './components/PageProINSEE/PageSearchINSEE';
import { Route, Router } from 'react-router-dom'
import { history } from './helpers';
import { alertActions } from './actions';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './helpers/customTheme';
import AddProPage from './components/PagePro/AddProPage';
import PageSearchUser from './components/Users/PageSearchUser';
import InfoUser from './components/Users/UserDetails';
import CurrentQuerie from './components/PageQueriesDetails/PageQueries';
import UserDownload from './components/Users/UserDownLoad';
import ProTopPage from './components/PagePro/ProTopPage';
import PageParking from './components/PageParking/PageSearchParking';
import InfoParking from './components/PageParking/ParkingDetails';
import PageSearchQueries from './components/PageAllQueries/PageSearchQueries';
import TaskPage from './components/TaskPage/TaskPage';
import ReportPage from './components/ReportsPage/ReportPage';
import PageChangeMail from './components/PageProINSEE/PageChangeMail';
import PageEditMessageType from './components/PageProINSEE/PageEditMessageType';

class App extends Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Router history={history}>
                    <div>
                        <PrivateRoute exact path="/" component={HomePage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/search" component={PageSearch} />
                        <Route path="/searchINSEE" component={PageSearchINSEE} />
                        <Route path="/changeMail/:id" component={PageChangeMail} />
                        <Route path="/editMessage/" component={PageEditMessageType} />
                        <Route path="/add-pro" component={AddProPage} />
                        <Route path="/search-user" component={PageSearchUser} />
                        <Route path="/info-user/:id" component={InfoUser} />
                        <Route path="/user-download" component={UserDownload} />
                        <Route path="/pro-details/:id" component={ProTopPage} />
                        <Route path="/queries/:id" component={CurrentQuerie} />
                        <Route path="/search-parking" component={PageParking} />
                        <Route path="/info-parking/:id" component={InfoParking} />
                        <Route path="/all-queries" component={PageSearchQueries} />
                        <Route path="/in-progress-queries" component={PageSearchQueries} />
                        <Route path="/ended-queries" component={PageSearchQueries} />
                        <Route path="/canceled-queries" component={PageSearchQueries} />
                        <Route path="/testPage-queries" component={PageSearchQueries} />
                        <Route path="/signin" component={SigninPage} />
                        <Route path="/tasks" component={TaskPage} />
                        <Route path='/reports' component={ReportPage} />
                    </div>
                </Router>
            </MuiThemeProvider>
        )
    }
}

export default connect()(App);
