import React, {Component} from 'react';
import ProList from './ProList';
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import SearchBoxPagePro from '../common/SearchBox'


class PageSearch extends Component {

    render() {
      console.log(this.props)
        return (
            <div>
                <Layout>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={12}>
                            <SearchBoxPagePro/>
                            <ProList/>
                        </Grid>
                    </Grid>
                </Layout>
            </div>
        )
    }
}

export default PageSearch;
