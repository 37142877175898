import React from 'react';
import {bindActionCreators} from 'redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import {postDatas} from "../../actions/postData.actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {fetchDatas} from "../../actions/fetch.actions";
import Selected from 'react-select';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import { API_URL } from "../../config";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
    modal: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    selected: {
      width : '70%',
      marginTop: '10px',
      marginLeft: '10px'
    }
});

const taskStatus = [
  {
    value: '0',
    label: 'A faire'
  },
  {
    value: '1',
    label: 'En cours'
  },
  {
    value: '2',
    label: 'Annuler'
},
{
    value: '3',
    label:'Terminer'
}
];

class FormDialogTask extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            title: '',
            description: '',
            date: '',
            status: '',
            admin: '',
            selectedOption: null,
            selectedOptionCompany : null,
            companies: []
        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillMount() {
      if (!this.props.adminInfo) {
        this.props.fetchDatas(API_URL + '/admingetall.php', 'adminInfo', 'POST');
      }

      if (!this.props.inqueries) {
        this.props.fetchDatas(API_URL + '/inquery/inquerygetall.php', 'inqueries', 'POST');
      }

      return fetch(API_URL + '/company/companyfortaskgetall.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken}
      }).then(response => {
        return response.json();
      }).then(json => {
        this.setState({companies: json})
        return json
      })
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    handleClose() {
        this.setState({Open: false})
    }

    handleChange(value, key) {
        this.setState({[key]: value})
    }

    onSave() {
      var data = new FormData();
      data.append("title", this.state.title);
      data.append("tasksdescription", this.state.description);
      data.append("adminid", this.state.admin);
      data.append("duedate", this.state.date);
      data.append("status", this.state.status);

      if (this.state.selectedOption) {
        data.append("inqueryid", this.state.selectedOption.value);
      }

      if (this.state.selectedOptionCompany) {
        data.append("companyid", this.state.selectedOptionCompany.value);
      }

      return fetch(API_URL + '/task/taskadd.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
        body: data
      }).then(response => {
        return response.json();
      }).then(json => {
        this.handleClose();
        window.location.reload();
        return json
      })
    }

    onChange = (selectedOption) => {
        this.setState({ selectedOption });
        var data = new FormData();
        data.append("inqueryid", selectedOption.value);
        return fetch(API_URL + '/inquery/inquerycompanygetall.php', {
          method: 'POST',
          headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
          body: data
        }).then(response => {
          return response.json();
        }).then(json => {
          this.setState({companies: json})
          return json
        })
      }

      onChangeCompany = (selectedOptionCompany) => {
        this.setState({selectedOptionCompany})
      }

    render() {
        const {classes, error, loading} = this.props;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <Button onClick={this.handleClickOpen} className={classes.modal} color="primary" variant="contained">Créer une tâche</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Détails de la tache</DialogTitle>
                    <DialogContent>
                        <TextField
                        id="outlined-title"
                        label="Titre"
                        placeholder="Titre"
                        value={this.state.title}
                        onChange={(e) => {this.handleChange(e.target.value, 'title')}}
                        margin="normal"
                        className={classes.textField}
                        variant="outlined"
                        fullWidth/>

                        <TextField
                        id="outlined-description"
                        label="Description"
                        value={this.state.description}
                        onChange={(e) => {this.handleChange(e.target.value, 'description')}}
                        margin="normal"
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="5"/>

                        <TextField
                        id="outlined-date"
                        label="Date d'échéance"
                        value={this.state.date}
                        onChange={(e) => {this.handleChange(e.target.value, 'date')}}
                        margin="normal"
                        type="date"
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth/>

                        <TextField
                        id="outlined-status"
                        label="Status"
                        value={this.state.status}
                        onChange={(e) => {this.handleChange(e.target.value, 'status')}}
                        margin="normal"
                        variant="outlined"
                        select
                        fullWidth
                        className={classes.textField}>
                        {taskStatus.map(task => (
                          <MenuItem key={task.value} value={task.value}>
                          {task.label}
                          </MenuItem>
                        ))}
                        </TextField>

                        <TextField
                        id="outlined-Admin"
                        label="Utilisateur"
                        value={this.state.admin}
                        onChange={(e) => {this.handleChange(e.target.value, 'admin')}}
                        margin="normal"
                        variant="outlined"
                        select
                        fullWidth
                        className={classes.textField}>
                          {this.props.adminInfo && this.props.adminInfo.map(admin => (
                            <MenuItem key={admin.AdminID} value={admin.AdminID}>
                            {admin.Username}
                            </MenuItem>
                          ))}
                        </TextField>

                        <Selected
                        className={classes.selected}
                        value={this.state.selectedOption}
                        onChange={this.onChange}
                        placeholder="Demandes"
                        options={this.props.inqueries}/>

                          <Selected
                          className={classes.selected}
                          value={this.state.selectedOptionCompany}
                          onChange={this.onChangeCompany}
                          placeholder="Professionnels"
                          options={this.state.companies}/>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Annuler</Button>
                        <Button onClick={this.onSave} color="primary">Enregistrer</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postDatas: bindActionCreators(postDatas, dispatch),
        fetchDatas: bindActionCreators(fetchDatas, dispatch),
        fetchDatasWithName: bindActionCreators(fetchDatasWithName, dispatch)
    }
}

function mapStateToProps(state) {
    return ({
        companies: state.datasReducer.companies,
        inqueries: state.datasReducer.inqueries,
        adminInfo: state.datasReducer.adminInfo,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FormDialogTask));
