import React, {Component} from 'react'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet'
import "../../index.css"
import L from 'leaflet';

import {connect} from 'react-redux';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {bindActionCreators} from 'redux';
import ProList from './ProList'


const customerBoatIcon = new L.Icon({
    iconUrl: require('../../images/Customer_Boat_Icon_2.svg'),
    iconSize: [46, 96],
    iconAnchor: [22, 70],
    popupAnchor: [0, -50]// point from which the popup should open relative to the iconAncho
});

class ProSelectMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: '',
            proSelected: [],
            proData: '',
        };
        this.distancecheck = this.distancecheck.bind(this);
    }

    componentWillMount() {
        this.setState({proData: this.props.proDatas && this.props.proDatas});
    }

    componentWillReceiveProps = props => {
        if (props.proDatas !== this.props.proDatas) {
            this.setState({proData: props.proDatas});
        }
    };

    distancecheck(data, radius) {
        if (this.props.radius) {
            var p = 0.017453292519943295;    // Math.PI / 180
            var c = Math.cos;
            var a = 0.5 - c((data.lat - data.latitude) * p) / 2 +
                c(data.latitude * p) * c(data.lat * p) *
                (1 - c((data.lng - data.longitude) * p)) / 2;
            return 12742 * Math.asin(Math.sqrt(a)) < radius; // 2 * R; R = 6371 km
        }
    }

    render() {
        const {error, loading, inqueryInfo} = this.props;
        const {proData} = this.state;
        const position = proData ? [proData[0].latitude, proData[0].longitude] : [40, 0];

        if (error) {
            return <div>Error! {error.message}</div>;
        }

        if (loading) {
            return <div>Loading </div>;
        }

        return (
            <div className='map'>
                <Map className='mapDisplay' center={position} zoom={5} minZoom={2}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {proData && proData.map((data, i) => {
                      if (data.CompanyName) {
                        if (data.CompanyName.toLowerCase().indexOf("boaton") != -1) {
                            console.log(data, (this.distancecheck(data, this.props.radius)), this.props.radius)
                        }
                    }

                    console.log(data.CompanyName)
                    console.log(this.props.name)
                    if (this.props.name && data.CompanyName && data.CompanyName.toLowerCase().search(this.props.name.toLowerCase())) {
                        return (<div/>)
                    }
                            return (
                                <div key={i}>
                                    {(this.props.activity === 'Service_Insurance' && data.Service_Insurance === '1') ?
                                        (
                                          <div>
                                                {(this.props.activity === 'Service_Insurance' && data.Service_Insurance === '1' && data.lat && data.lng) &&
                                                <Marker key={i} position={[data.lat, data.lng]}>
                                                    {this.state.proSelected.push(data)}

                                                    <Popup>
                                                        <p> {data.CompanyName}</p>
                                                    </Popup>
                                                </Marker>
                                                }
                                            </div>
                                        ) : (
                                            <div>
                                                {this.props.radius && data.lat && data.lng ? (
                                                        <div>

                                                            {(this.distancecheck(data, this.props.radius) && (
                                                                (this.props.activity === 'Service_Seller' && data.Service_Seller === '1') ||
                                                                (this.props.activity === 'Service_Cleaner' && data.Service_Cleaner === '1') ||
                                                                (this.props.activity === 'Service_Repair' && data.Service_Repair === '1') ||
                                                                (this.props.activity === 'Service_BoatLifting' && data.Service_BoatLifting === '1') ||
                                                                (this.props.activity === 'Service_BoatGarage' && data.Service_BoatGarage === '1') ||
                                                                (this.props.activity === 'Service_CareTaker' && data.Service_CareTaker === '1') ||
                                                                (this.props.activity === 'Service_Maker' && data.Service_Maker === '1') ||
                                                                (this.props.activity === 'Service_Rent' && data.Service_Rent === '1') ||
                                                                (this.props.activity === 'Service_Chandlery' && data.Service_Chandlery === '1') ||
                                                                (this.props.activity === 'Service_HarbourSpots' && data.Service_HarbourSpots === '1') ||
                                                                (this.props.activity === 'Service_Other' && data.Service_Other === '1') ||
                                                                (this.props.activity === 'Service_SellerTrailer' && data.Service_SellerTrailer === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine' && data.Service_RepairEngine === '1') ||
                                                                (this.props.activity === 'Service_SellEngineSpareParts' && data.Service_SellEngineSpareParts === '1') ||
                                                                (this.props.activity === 'Service_SailManufacturerAndRepair' && data.Service_SailManufacturerAndRepair === '1') ||
                                                                (this.props.activity === 'Service_RepairElectronics' && data.Service_RepairElectronics === '1') ||
                                                                (this.props.activity === 'Service_Electrics' && data.Service_Electrics === '1') ||
                                                                (this.props.activity === 'Service_SellerFishingEquipment' && data.Service_SellerFishingEquipment === '1') ||
                                                                (this.props.activity === 'Service_DryMarina' && data.Service_DryMarina === '1') ||
                                                                (this.props.activity === 'Service_Expertise' && data.Service_Expertise === '1') ||

                                                                (this.props.activity === 'Service_SellerEngine' && data.Service_SellerEngine === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine_InBord' && data.Service_RepairEngine_InBord === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine_HorsBord' && data.Service_RepairEngine_HorsBord === '1') ||
                                                                (this.props.activity === 'Service_SellerBoatLeather' && data.Service_SellerBoatLeather === '1') ||
                                                                (this.props.activity === 'Service_BoatRecycling' && data.Service_BoatRecycling === '1') ||
                                                                (this.props.activity === 'Service_BoatTowing' && data.Service_BoatTowing === '1') ||
                                                                (this.props.activity === 'Service_Insurance' && data.Service_Insurance === '1') ||

                                                                (this.props.activity === 'Service_BoatTransportation' && data.Service_BoatTransportation === '1') ||
                                                                (this.props.activity === 'Service_MarineCarpentry' && data.Service_MarineCarpentry === '1') ||
                                                                (this.props.activity === 'Service_NavalArchitect' && data.Service_NavalArchitect === '1') ||
                                                                (this.props.activity === 'Service_StainlessSteelSpecialist' && data.Service_StainlessSteelSpecialist === '1') ||
                                                                (this.props.activity === 'Service_UnderwaterWorksAndDivers' && data.Service_UnderwaterWorksAndDivers === '1') ||
                                                                (this.props.activity === 'Service_HeatingSpecialist' && data.Service_HeatingSpecialist === '1') ||
                                                                (this.props.activity === 'Service_FireProtection' && data.Service_FireProtection === '1') ||
                                                                (this.props.activity === 'Service_InflatableReparation' && data.Service_InflatableReparation === '1') ||
                                                                (this.props.activity === 'Service_Photographer' && data.Service_Photographer === '1') ||
                                                                (this.props.activity === 'Service_Videomaker' && data.Service_Videomaker === '1') ||
                                                                (this.props.activity === 'Service_BoatLicense' && data.Service_BoatLicense === '1') ||
                                                                (this.props.activity === 'Service_SaillingSchool' && data.Service_SaillingSchool === '1') ||
                                                                (this.props.activity === 'Service_GateringOnBoard' && data.Service_GateringOnBoard === '1') ||
                                                                (this.props.activity === 'Service_EventPlanning' && data.Service_EventPlanning === '1') ||
                                                                (this.props.activity === 'Service_SailingCleaning' && data.Service_SailingCleaning === '1') ||
                                                                (this.props.activity === 'Service_PaintingHull' && data.Service_PaintingHull === '1') ||
                                                                (this.props.activity === 'Service_ManufactingRigging' && data.Service_ManufactingRigging === '1') ||
                                                                (this.props.activity === 'Service_Stratification' && data.Service_Stratification === '1') ||
                                                                (this.props.activity === 'Service_Carpentry' && data.	Service_Carpentry === '1') ||
                                                                (this.props.activity === 'Service_Mature_Ajustment' && data.Service_Mature_Ajustment === '1') ||
                                                                (this.props.activity === 'Service_New_Boat' && data.Service_New_Boat === '1') ||
                                                                (this.props.activity === 'Service_Boat_Transport' && data.Service_Boat_Transport === '1')
                                                            ))
                                                            &&
                                                            <Marker key={i} position={[data.lat, data.lng]}>
                                                                {this.state.proSelected.push(data)}
                                                                <Popup>
                                                                    <p> {data.CompanyName}</p>
                                                                </Popup>
                                                            </Marker>}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            &&
                                                            {/*Affichage de tout les marqueurs (attention, mauvaise idée)*/}

                                                            {/*<Marker key={i} position={[data.lat, data.lng]}>*/}
                                                            {/*<Popup>*/}

                                                            {/*<p> {data.CompanyName}</p>*/}

                                                            {/*</Popup>*/}
                                                            {/*</Marker>*/}
                                                            }

                                                        </div>
                                                    )}
                                                    {!this.props.radius && this.props.activity && data.lat && data.lng ? (
                                                        <div>

                                                            { (
                                                                (this.props.activity === 'Service_Seller' && data.Service_Seller === '1') ||
                                                                (this.props.activity === 'Service_Cleaner' && data.Service_Cleaner === '1') ||
                                                                (this.props.activity === 'Service_Repair' && data.Service_Repair === '1') ||
                                                                (this.props.activity === 'Service_BoatLifting' && data.Service_BoatLifting === '1') ||
                                                                (this.props.activity === 'Service_BoatGarage' && data.Service_BoatGarage === '1') ||
                                                                (this.props.activity === 'Service_CareTaker' && data.Service_CareTaker === '1') ||
                                                                (this.props.activity === 'Service_Maker' && data.Service_Maker === '1') ||
                                                                (this.props.activity === 'Service_Rent' && data.Service_Rent === '1') ||
                                                                (this.props.activity === 'Service_Chandlery' && data.Service_Chandlery === '1') ||
                                                                (this.props.activity === 'Service_HarbourSpots' && data.Service_HarbourSpots === '1') ||
                                                                (this.props.activity === 'Service_Other' && data.Service_Other === '1') ||
                                                                (this.props.activity === 'Service_SellerTrailer' && data.Service_SellerTrailer === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine' && data.Service_RepairEngine === '1') ||
                                                                (this.props.activity === 'Service_SellEngineSpareParts' && data.Service_SellEngineSpareParts === '1') ||
                                                                (this.props.activity === 'Service_SailManufacturerAndRepair' && data.Service_SailManufacturerAndRepair === '1') ||
                                                                (this.props.activity === 'Service_RepairElectronics' && data.Service_RepairElectronics === '1') ||
                                                                (this.props.activity === 'Service_Electrics' && data.Service_Electrics === '1') ||
                                                                (this.props.activity === 'Service_SellerFishingEquipment' && data.Service_SellerFishingEquipment === '1') ||
                                                                (this.props.activity === 'Service_DryMarina' && data.Service_DryMarina === '1') ||
                                                                (this.props.activity === 'Service_Expertise' && data.Service_Expertise === '1') ||

                                                                (this.props.activity === 'Service_SellerEngine' && data.Service_SellerEngine === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine_InBord' && data.Service_RepairEngine_InBord === '1') ||
                                                                (this.props.activity === 'Service_RepairEngine_HorsBord' && data.Service_RepairEngine_HorsBord === '1') ||
                                                                (this.props.activity === 'Service_SellerBoatLeather' && data.Service_SellerBoatLeather === '1') ||
                                                                (this.props.activity === 'Service_BoatRecycling' && data.Service_BoatRecycling === '1') ||
                                                                (this.props.activity === 'Service_BoatTowing' && data.Service_BoatTowing === '1') ||
                                                                (this.props.activity === 'Service_Insurance' && data.Service_Insurance === '1') ||

                                                                (this.props.activity === 'Service_BoatTransportation' && data.Service_BoatTransportation === '1') ||
                                                                (this.props.activity === 'Service_MarineCarpentry' && data.Service_MarineCarpentry === '1') ||
                                                                (this.props.activity === 'Service_NavalArchitect' && data.Service_NavalArchitect === '1') ||
                                                                (this.props.activity === 'Service_StainlessSteelSpecialist' && data.Service_StainlessSteelSpecialist === '1') ||
                                                                (this.props.activity === 'Service_UnderwaterWorksAndDivers' && data.Service_UnderwaterWorksAndDivers === '1') ||
                                                                (this.props.activity === 'Service_HeatingSpecialist' && data.Service_HeatingSpecialist === '1') ||
                                                                (this.props.activity === 'Service_FireProtection' && data.Service_FireProtection === '1') ||
                                                                (this.props.activity === 'Service_InflatableReparation' && data.Service_InflatableReparation === '1') ||
                                                                (this.props.activity === 'Service_Photographer' && data.Service_Photographer === '1') ||
                                                                (this.props.activity === 'Service_Videomaker' && data.Service_Videomaker === '1') ||
                                                                (this.props.activity === 'Service_BoatLicense' && data.Service_BoatLicense === '1') ||
                                                                (this.props.activity === 'Service_SaillingSchool' && data.Service_SaillingSchool === '1') ||
                                                                (this.props.activity === 'Service_GateringOnBoard' && data.Service_GateringOnBoard === '1') ||
                                                                (this.props.activity === 'Service_EventPlanning' && data.Service_EventPlanning === '1') ||
                                                                (this.props.activity === 'Service_SailingCleaning' && data.Service_SailingCleaning === '1') ||
                                                                (this.props.activity === 'Service_PaintingHull' && data.Service_PaintingHull === '1') ||
                                                                (this.props.activity === 'Service_ManufactingRigging' && data.Service_ManufactingRigging === '1') ||
                                                                (this.props.activity === 'Service_Stratification' && data.Service_Stratification === '1') ||
                                                                (this.props.activity === 'Service_Carpentry' && data.	Service_Carpentry === '1') ||
                                                                (this.props.activity === 'Service_Mature_Ajustment' && data.Service_Mature_Ajustment === '1') ||
                                                                (this.props.activity === 'Service_New_Boat' && data.Service_New_Boat === '1') ||
                                                                (this.props.activity === 'Service_Boat_Transport' && data.Service_Boat_Transport === '1')
                                                            )
                                                            &&
                                                            <Marker key={i} position={[data.lat, data.lng]}>
                                                                {this.state.proSelected.push(data)}
                                                                <Popup>
                                                                    <p> {data.CompanyName}</p>
                                                                </Popup>
                                                            </Marker>}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            &&
                                                            {/*Affichage de tout les marqueurs (attention, mauvaise idée)*/}

                                                            {/*<Marker key={i} position={[data.lat, data.lng]}>*/}
                                                            {/*<Popup>*/}

                                                            {/*<p> {data.CompanyName}</p>*/}

                                                            {/*</Popup>*/}
                                                            {/*</Marker>*/}
                                                            }

                                                        </div>
                                                    )}
                                            </div>
                                        )}

                                </div>
                            )
                        }
                    )}
                    {inqueryInfo &&
                    <Marker
                        position={[inqueryInfo.lat, inqueryInfo.lng]}
                        icon={customerBoatIcon}>
                        <Popup>
                            <p> {inqueryInfo.FirstName} {inqueryInfo.LasterName} </p>
                        </Popup>
                    </Marker>}
                </Map>
                <ProList id={this.props.id} proDatas={this.state.proSelected}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    inqueryInfo: state.datasReducer.inqueryInfo,
    oneQuerie: state.datasReducer.oneQuerie,
    proDatas: state.datasReducer.proDatas,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
});

const mapDispatchToProps = (dispatch) => ({
    fetchDatasWithName: bindActionCreators(fetchDatasWithName, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(ProSelectMap)
