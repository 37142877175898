import {POST_DATA_ERROR, POST_DATA_SUCCESS} from '../actions/postData.actions';

const initialState = {
    datas: [],
    error: [],
};

export default function postDatasReducer(state = initialState, action) {
    switch (action.type) {

        case POST_DATA_SUCCESS:
            return {
                ...state,
                datas: action.payload
            };

        case POST_DATA_ERROR:
            return {
                ...state,
                error: action.error
            };

        default:
            return state;
    }
}