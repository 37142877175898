export function fetchDatas(url, typeFetch, method, body = null) {
    let user = JSON.parse(localStorage.getItem('user'));
    var option = {};
    if(user.user){
        option = {
            method: method,
            headers: {'X-ACCESS-TOKEN': user.user.accesstoken}
        };
    }else{
        option = {
            method: method,
        };
    }

    if (body) {
        option.body = body;
    }

    return dispatch => {
        dispatch(fetchDatasBegin());
        return fetch(url, option)
            .then(handleErrors)
            .then(res => {
                return res.json()
            })
            .then(json => {
                dispatch(fetchDatasSuccess(json, typeFetch, method));
                return json;
            })
            .catch(error => dispatch(fetchDatasError(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const FETCH_DATAS_BEGIN = 'FETCH_DATAS_BEGIN';
export const FETCH_DATAS_SUCCESS = 'FETCH_DATAS_SUCCESS';
export const FETCH_DATAS_ERROR = 'FETCH_DATAS_ERROR';

export const fetchDatasBegin = () => ({
    type: FETCH_DATAS_BEGIN
});

export const fetchDatasSuccess = (data, typeFetch, method) => ({
    type: FETCH_DATAS_SUCCESS,
    payload: {datas: data},
    url: typeFetch,
    urlType: method
});

export const fetchDatasError = error => ({
    type: FETCH_DATAS_ERROR,
    payload: {error}
});
