import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DraftsIcon from '@material-ui/icons/Drafts'
import Home from '@material-ui/icons/Home'
import ListIcon from '@material-ui/icons/List'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'


import AccountCircle from '@material-ui/icons/AccountCircle'
import Build from '@material-ui/icons/Build'
import RvHookup from '@material-ui/icons/RvHookup'
import Feedback from '@material-ui/icons/Feedback'
import Dvr from '@material-ui/icons/Dvr'

const style = () => ({
  root: {
    color: 'white',
    '&:hover': {
      textDecoration: 'none',
      color: 'white'
    },
    '&:focus': {
      textDecoration: 'none'
    }
  }
})

export default withStyles(style)((props) => {
  const {classes} = props


  return (
    <div>
      <List className={classes.root} component="nav">
        <ListItem button className={classes.root} component={Link} to="/">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Accueil" disableTypography={true}/>
        </ListItem>

        <Divider/>

        <ListItem button component={Link} to="/all-queries" className={classes.root}>
          <ListItemIcon>
            <DraftsIcon color="action"/>
          </ListItemIcon>
          <ListItemText primary="Toutes les demandes" disableTypography={true}/>
        </ListItem>

        <Divider/>
        <ListItem button component={Link} to="/tasks" className={classes.root}>
          <ListItemIcon>
            <ListIcon color="action"/>
          </ListItemIcon>
          <ListItemText primary="Taches" disableTypography={true}/>
        </ListItem>
        <Divider/>

        <ListItem button component={Link} to="/search" className={classes.root}>
          <ListItemIcon>
            <Build color="action"/>
          </ListItemIcon>
          <ListItemText primary="Professionels" disableTypography={true}/>
        </ListItem>

        <Divider/>

        <ListItem button component={Link} to="/search-user" className={classes.root}>
          <ListItemIcon>
            <AccountCircle color="action"/>
          </ListItemIcon>
          <ListItemText primary="Utilisateurs" disableTypography={true}/>
        </ListItem>

        <Divider/>

        <ListItem button component={Link} to="/search-parking" className={classes.root}>
          <ListItemIcon>
            <RvHookup color="action"/>
          </ListItemIcon>
          <ListItemText primary="Parking" disableTypography={true}/>
        </ListItem>

        <Divider/>

        <ListItem button component={Link} to="/reports" className={classes.root}>
          <ListItemIcon>
            <Feedback color="action"/>
          </ListItemIcon>
          <ListItemText primary="Reports" disableTypography={true}/>
        </ListItem>

        <Divider/>

        <ListItem button component={Link} to="/searchINSEE" className={classes.root}>
          <ListItemIcon>
            <Dvr color="action"/>
          </ListItemIcon>
          <ListItemText primary="Pros INSEE" disableTypography={true}/>
        </ListItem>

      </List>


    </div>
  )
})
