import {userConstants} from '../constants';
import {userService} from '../services';
import {alertActions} from './';
import {history} from '../helpers';

export const userActions = {
    login,
    logout,
    signin
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));
        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user, username));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(user, username) {
        return {type: userConstants.LOGIN_SUCCESS, user, username}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function signin(username, email, password) {
    return dispatch => {
        dispatch(request({username}));
        userService.signin(username, email, password)
            .then(
                user => {
                    dispatch(success(user, username));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(user, username) {
        return {type: userConstants.LOGIN_SUCCESS, user, username}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    userService.logout();
    return {type: userConstants.LOGOUT};
}
