import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {postDatas} from "../../actions/postData.actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {fetchDatas} from "../../actions/fetch.actions";
import { API_URL } from "../../config";

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
});

class FormDialogTask extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Open: false,
            title: '',
            description: '',
            duedate: new Date().toISOString().split('T')[0],
            adminid: '',
            status: '',

        };

        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const {title, description, duedate, adminid, status} = this.state;

        const data = new FormData();
        data.append("title", title);
        data.append("tasksdescription", description);
        data.append("status", status);
        data.append("duedate", duedate);
        data.append("adminid", adminid);
        data.append("companyid", this.props.CompanyID);
        data.append('inqueryid', this.props.InqueryID);
        this.props.dispatch(postDatas(API_URL + '/task/taskadd.php', data));
        this.handleClose();
    }

    handleClickOpen() {
        this.setState({Open: true})
    }

    onChange(value, key) {
        this.setState({[key]: value});
    };

    handleClose() {
        this.setState({Open: false})
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });

    }

    render() {
        const {classes, error, loading} = this.props;

        const {title, description, duedate, adminid, status} = this.state;

        if (error) {
            return (<div>Error! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        console.log(duedate)

        return (
            <div>
                <Button onClick={this.handleClickOpen}>Créer une tâche</Button>
                <Dialog open={this.state.Open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Détails de la tache</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="standard-textarea"
                                    label="Titre"
                                    value={title}
                                    onChange={(e) => {this.onChange(e.target.value, 'title')}}
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabel={{
                                        shrink: true,
                                    }}/>

                                <TextField
                                    id="standard-textarea"
                                    label="Description"
                                    value={description}
                                    onChange={(e) => {this.onChange(e.target.value, 'description')}}
                                    multiline
                                    className={classes.textField}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}/>

                                <TextField
                                    id="date"
                                    label="date"
                                    type="date"
                                    value={duedate}
                                    onChange={(e) => {
                                        this.onChange(e.target.value, 'duedate')
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <FormControl>
                                    <InputLabel htmlFor="type">Status</InputLabel>
                                    <Select
                                        value={status}
                                        onChange={(e) => {
                                            this.onChange(e.target.value, 'status')
                                        }}
                                        inputProps={{
                                            name: 'status',
                                            id: 'status-id',
                                        }}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="0">A faire</MenuItem>
                                        <MenuItem value="1">En cours</MenuItem>
                                        <MenuItem value="2">Annuler</MenuItem>
                                        <MenuItem value="3">Terminer</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <InputLabel htmlFor="type">Administrateur affilié</InputLabel>
                                    <Select
                                        value={adminid}
                                        onChange={(e) => this.onChange(e.target.value, 'adminid')}
                                        inputProps={{
                                            name: 'adminid',
                                            id: 'adminid-id',
                                        }}>
                                        {this.props.adminInfo && this.props.adminInfo.map((adminDatas, i) => {
                                            return (
                                                <MenuItem key={i} value={parseInt(adminDatas.AdminID)}>{adminDatas.Username.toString()}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Envoyer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        adminInfo: state.datasReducer.adminInfo,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(FormDialogTask));
