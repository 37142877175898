import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MessageEditor from "./MessageEditor"

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 120,
        height: "800px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        textAlign: 'center'
    },
    hidden: {
        visibility: 'visible'
    },
});

class SendButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    handleOpen = () => {
        this.setState({open: true})
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        console.log(this.props)
        const {classes} = this.props;
        return (
            <div>
                <Button variant="contained" color="primary" onClick={this.handleOpen}>VALIDER</Button>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    classes={{hidden: classes.hidden}}>
                    <div className={classes.paper}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={12}>
                                <MessageEditor id={this.props.id} idsList={this.props.idsList} close={this.handleClose}/>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(SendButton);
