export default theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      display: 'flex',
      padding: 10,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
      }
    },
    card: {
        width: '100%',
    },
    cardContent: {
        width: '90%',
        margin: 'auto',
    },
    buttons: {
        width: '80%',
        margin: 'auto',
        display: 'flex', 
        flexDirection: 'space-between'
    },
    buttonCancel: {
        marginLeft: '2%',
        marginRight: 'auto',
        width: '45%',
        fontSize: '16px'
    },
    buttonValidate: {
        display: 'flex',
        width: '100%',
        fontSize: '16px',
        border: '1px solid black'
    }
});
