import React, {Component} from 'react';
import {fetchDatas} from "../../actions/fetch.actions";
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {postDatas} from "../../actions/postData.actions";
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import Card from '@material-ui/core/Card';
import SearchBoxPagePro from '../common/SearchBox'
import { TextField } from '@material-ui/core/es';
import {connect} from "react-redux";
import {withStyles} from '@material-ui/core/styles';
import styles from './Styles/EditMessageTypeCss';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { API_URL } from "../../config";

//Champs à éditer :
    // CompanyName
    // PrenomRepresentantLegal
    // NomRepresentantLegal
    // NumeroVoieAdresse
    // TypeVoieAdresse
    // LibelleVoieAdresse
    // ComplementAdresse
    // Ville
    // Code_postal
    // ContentMessage

class EditMessageType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageType: '',
        }
    }

    componentWillMount() {

    }

    handleChange(value, key) {
        this.setState({[key]: value})
    }

    validate() {
        console.log("validation");
        console.log(this.state.messageType);
        var data = new FormData();
        data.append("messageType", this.state.messageType);
        this.props.dispatch(postDatas(API_URL + '/company/companyINSEEEditMessageForAll.php', data)).then( result => {
            console.log(result);
         });
        if(window.confirm('En acceptant, vous éditez tous les contenus des courriers n\'ayant pas été édités manuellement. Are you sure ?')){
            window.history.back();
        }

    }

    render() {
        const {classes} = this.props;
        // console.log(this.state);
        return (
            <div>
                <Layout>
                    <Grid container spacing={24}>
                        <div className={classes.root}>
                            <Card className={classes.card}>
                                <div className={classes.cardContent}>
                                    <TextField
                                    id="messageType"
                                    value={this.state.messageType}
                                    onChange={(e) => {this.handleChange(e.target.value, 'messageType')}}
                                    margin="normal"
                                    inputProps={{
                                        maxLength: 1800,
                                    }}
                                    multiline
                                    rows="15"
                                    variant="outlined"
                                    fullWidth
                                    />
                                    <Typography style={{textAlign: 'center'}}>En cliquant sur valider, vous affecterez votre message type à toutes les entreprises n'ayant pas encore été éditée (MAX 1800)</Typography>
                                    <Button color="secondary" variant="contained" className={classes.buttonValidate} onClick={() => this.validate()}>Valider</Button>
                                </div>
                            </Card>
                        </div>
                    </Grid>
                </Layout>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return ({
      INSEEproData: state.datasReducer.INSEEproData,
    })
  }

export default withStyles(styles)(connect(mapStateToProps)(EditMessageType));
