// import React...
import React from 'react';
import FullCalendar from 'fullcalendar-reactwrapper';
import '../../../node_modules/fullcalendar-reactwrapper/dist/css/fullcalendar.min.css'
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {history} from '../../helpers';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    divButton: {
        display: 'flex',
        flexFlow: 'row',
    },
    button: {
        marginRight: '10px'
    }
});

class ParkingDisponibility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            events: [],
            calendar: this.props.calendar,
            popup: [],
            currentView: new Date().getFullYear().toString(),
        };

        this.eventClick = this.eventClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.modalrender = this.modalrender.bind(this);
        this.goToInquery = this.goToInquery.bind(this);
    }

    componentDidMount() {
        let events = [...this.state.events];

        this.state.calendar && this.state.calendar.map((n) => {
            if (n.OrderStartDate != null && n.OrderStatus != "3") {
                if (n.BuyerID == n.SellerID) {
                    events.push({
                        events: 'Date bloqué',
                        title: "",
                        start: n.OrderStartDate,
                        end: n.OrderEndDate,
                        id: n.OrderID,
                        url: "",
                        color: '#807f7d',
                        textColor: 'white'
                    });
                } else if (n.OrderStatus == "1" || n.OrderStatus == "2") {
                    events.push({
                        events: 'Demande en attente',
                        title: n.FirstName + ' ' + n.LasterName,
                        start: n.OrderStartDate,
                        end: n.OrderEndDate,
                        id: n.OrderID,
                        price: n.Price,
                        color: '#008489',
                        textColor: 'white',
                        InqueryID: n.InqueryID,
                        BuyerID: n.BuyerID
                    });
                } else {
                    events.push({
                        events: 'Demande accepté',
                        title: n.FirstName + ' ' + n.LasterName,
                        start: n.OrderStartDate,
                        end: n.OrderEndDate,
                        id: n.OrderID,
                        price: n.Price,
                        color: '#fec107',
                        textColor: 'white',
                        InqueryID: n.InqueryID,
                        BuyerID: n.BuyerID
                    });
                }
            }
        });
        this.setState({events: events});
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    eventClick = (event, jsEvent, view) => {
        this.state.popup.push(event);
        var currentView1 = moment(view.calendar.currentDate).format('YYYY-MM');
        this.setState({currentView: currentView1},);
        this.handleOpen();
    };

    goToUser = (id) => {
        history.push('/info-user/' + id);
    }

    goToInquery(id) {
        history.push('/queries/' + id);
    }

    modalrender = () => {
        return (
            <div>
                <Typography>{this.state.popup[0].events}</Typography>
                <Typography>Utilisateur : {this.state.popup[0].title}</Typography>
                <Typography>Debut : <Moment format="DD/MM/YYYY">{this.state.popup[0].start}</Moment></Typography>
                <Typography>Fin : <Moment format="DD/MM/YYYY">{this.state.popup[0].end}</Moment></Typography>
                <Typography>Prix : {this.state.popup[0].price} €</Typography>
                <div className={this.props.classes.divButton}>
                    <Button className={this.props.classes.button} color="primary" variant="contained" onClick={() => {this.goToUser(this.state.popup[0].BuyerID)}}>Voir l'Utilisateur</Button>
                    <Button className={this.props.classes.button} color="primary" variant="contained" onClick={() => {this.goToInquery(this.state.popup[0].InqueryID)}}>Aller à la demande</Button>
                    <Button className={this.props.classes.button} color="primary" variant="contained">Supprimer la demande</Button>
                </div>
            </div>
        );
    };

    render() {
        const {classes, loading, error} = this.props;

        if (error) {
            return (<div>error in main page! {error.message} </div>);
        }

        if (loading) {
            return <div>Loading ...</div>
        }

        return (
            <div>
                <FullCalendar
                    locale="fr"
                    id="calendar"
                    header={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'today',
                    }}
                    navLinks={true} // can click day/week names to navigate views
                    editable={false}
                    eventLimit={true} // allow "more" link when too many events
                    events={this.state.events}
                    eventClick={this.eventClick}
                    defaultDate={Date()}/>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}>
                    <div id="modal" style={getModalStyle()} className={classes.paper}>
                        <Typography variant="subtitle1" id="simple-modal-description">
                            {this.state.open && this.modalrender()}
                        </Typography>
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        parkingCalendar: state.datasReducer.parkingCalendar,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error
    })
}

export default withStyles(styles)(connect(mapStateToProps)(ParkingDisponibility));
