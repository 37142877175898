import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Create from '@material-ui/icons/Create'
import Moment from 'react-moment'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3
    },
    showMoreButton: {
        margin: '5%',
        width: '150%',
        borderRadius: '20px'
    },
    tablecellmd: {
        width: 20,
        padding: '0 5px 0 5px'
    },
    tablecellsm: {
        width: 15,
        padding: 0
    },
    midPrioButton: {
        backgroundColor: amber[500],
        '&:hover': {
            backgroundColor: amber[700]
        },
        fontSize: '0.7500rem'
    },
    avatarButton: {
        width: 25,
        height: 25,
        marginRight: 10
    },
    avatarSize: {
        width: 20,
        height: 20,
        fontSize: '0.870rem'
    },
    date: {
        paddingRight: '10px',
        whiteSpace: 'nowrap'
    }
})

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => {
            return (b[orderBy] <= a[orderBy] ? -1 : 1)
        }
        : (a, b) => {
            return (a[orderBy] <= b[orderBy] ? -1 : 1)
        }
}

class ProfessionalsTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            order: 'asc',
            orderBy: 'client'
        }
    }

    handleRequestSort = (property) => {
        const orderBy = property
        let order = 'desc'

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc'
        }

        this.setState({ order, orderBy })
    }


    render() {
        const { classes, error, loading, current } = this.props
        const { order, orderBy } = this.state

        if (error) {
            return <div>Error! {error.message}</div>
        }

        if (loading) {
            return <div>Loading...</div>
        }

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={orderBy === 'nom du pro' ? order : false}>
                                <Tooltip
                                    title="Tri"
                                    placement={'bottom-end'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === 'nom du pro'}
                                        direction={order}
                                        onClick={() => this.handleRequestSort('nom du pro')}>
                                        Nom du pro
                  </TableSortLabel>
                                </Tooltip>
                            </TableCell>

                            <TableCell sortDirection={orderBy === 'date de la demande de devis' ? order : false}>
                                <Tooltip
                                    title="Tri"
                                    placement={'bottom-end'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === 'date de la demande de devis'}
                                        direction={order}
                                        onClick={() => this.handleRequestSort('date de la demande de devis')}>
                                        Date de la demande de devis
                  </TableSortLabel>
                                </Tooltip>
                            </TableCell>

                            <TableCell sortDirection={orderBy === 'statut' ? order : false}>
                                <Tooltip
                                    title="Tri"
                                    placement={'bottom-end'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === 'statut'}
                                        direction={order}
                                        onClick={() => this.handleRequestSort('statut')}>
                                        Statut
                  </TableSortLabel>
                                </Tooltip>
                            </TableCell>

                            <TableCell sortDirection={orderBy === 'date du dernier echange' ? order : false}>
                                <Tooltip
                                    title="Tri"
                                    placement={'bottom-end'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === 'date du dernier echange'}
                                        direction={order}
                                        onClick={() => this.handleRequestSort('date du dernier echange')}>
                                        Date du dernier echange
                  </TableSortLabel>
                                </Tooltip>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {current.sort(getSorting(order, orderBy)).map(data => {
                            return (

                                // verifier le nom des data

                                <TableRow key={data.InqueryID}>

                                    <TableCell component="th" scope="row" padding='checkbox'>
                                        {data.proName}
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding='checkbox'>
                                        <Moment format="DD/MM/YYYY">{data.InquerySubmissionDate.substring(0, 10)}</Moment>
                                    </TableCell>

                                    <TableCell component="th" scope="row" padding='checkbox'>
                                        {data.InqueryStatus}
                                    </TableCell>

                                    <TableCell
                                        className={classes.tablecellmd}>
                                        <Moment format="DD/MM/YYYY">{data.InquerySubmissionDate.substring(0, 10)}</Moment>
                                    </TableCell>

                                    <TableCell className={classes.tablecellmd}>
                                        <Grid container spacing={40}>
                                            {data.companyList && data.companyList.map((company, i) => (
                                                <Grid item xs={3} key={i}>
                                                    <IconButton className={classes.avatarButton}>
                                                        <Avatar className={classes.avatarSize}>
                                                            {company.name.match(/[A-Z]/g).join('').substring(0, 2)}
                                                        </Avatar>
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TableCell>

                                    <TableCell padding='checkbox'>
                                        <IconButton component={Link} to={'/queries/' + data.InqueryID}>
                                            <Create color='primary' />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return ({
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
        current: state.datasReducer.current
    })
}

export default withStyles(styles)(connect(mapStateToProps)(ProfessionalsTab))
