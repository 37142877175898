import {combineReducers} from 'redux';

import {authentication} from './authentication.reducer';
import {alert} from './alert.reducer';
import openDrawer from './openDrawer'
import datasReducer from './fetch.reducer';
import searchReducer from './searchBar.reducer';
import proListCheckBoxReducer from './proListCheckBox.reducer';
import postDatasReducer from './postDatas.reducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    open: openDrawer,
    datasReducer,
    searchReducer,
    proListCheckBoxReducer,
    postDatasReducer
});

export default rootReducer;