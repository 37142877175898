//Basic imports
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SideMenuItems from './SideMenuLinks'
import logo from '../../images/BoatOn_Logo.png'
import avatar from '../../images/BoatOn_Monogram.png'
//material imports
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ExitToApp from '@material-ui/icons/ExitToApp'
//redux imports
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import closeDrawer from '../../actions/closeDrawer.js'
import openDrawer from '../../actions/openDrawer.js'
import { CssBaseline } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import * as theme from 'leaflet'

const drawerWidth = 240

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  titleMenu: {
    flex: 1,
    margin: 15
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    paddingLeft: 20,
    paddingRight: 20,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    backgroundColor: '#303F9F',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    minHeight: '100vh',
    height: '100%'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '80px'
  },
  content: {
    marginTop: 80,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    width:'100%',
    'overflow-x': 'hidden',
  },
  flex: {
    flex: 1
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 60,
    height: 60
  },
  userName: {
    textTransform: 'capitalize'
  }
})

class Layout extends Component {

  render() {
    let username = localStorage.getItem('username')
    const {classes, state} = this.props
    return (

      <div className={classes.root}>
        <CssBaseline/>
        {/* top application toolbar */}
        <AppBar
          color="inherit"
          position="absolute"
          className={classNames(classes.appBar, state && classes.appBarShift)}>
          <Toolbar disableGutters={true}>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.props.openDrawer()}
              className={classNames(classes.menuButton, state && classes.hide)}
            >
              <MenuIcon/>
            </IconButton>

            <Link to="/" className={classes.flex}><img src={logo} width="150" alt="BoatOn"/></Link>

            <Link to="/login">
              <IconButton color="inherit" className={classes.logOut}>
                <ExitToApp/>
              </IconButton> Logout
            </Link>

            <Avatar src={avatar} className={classNames(classes.avatar, classes.bigAvatar)} />

            <Typography className={classes.userName} color="inherit">
              {username}
            </Typography>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !state && classes.drawerPaperClose)
          }}
          open={!state}>
          <div className={classes.toolbar}>
            <IconButton onClick={() => this.props.closeDrawer()}>
              {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
          </div>
          <Divider/>
          <List>
            {/* left side menu */}
            <SideMenuItems/>
          </List>
        </Drawer>

        {/* main content container */}
        <main className={classes.content}>
          {this.props.children}
        </main>
      </div>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    state: state.open
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({openDrawer, closeDrawer}, dispatch)
}

const NavigationConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, {withTheme: true})(Layout))

export default NavigationConnect
