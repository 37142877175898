import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {proListCheckBox} from "../../actions/proListCheckBox.actions";
import SendButton from ".//SendButton";

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => {
            return (b[orderBy] <= a[orderBy] ? -1 : 1)
        }
        : (a, b) => {
            return (a[orderBy] <= b[orderBy] ? -1 : 1)
        };
}

const columnData = [
    {id: 'name', numeric: false, disablePadding: true, label: 'Professionnel'},
    {id: 'localisation', numeric: false, disablePadding: false, label: 'Localisation'},
    {id: 'rating', numeric: true, disablePadding: false, label: 'Notes'},

];

class EnhancedTableHead extends React.Component {

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                    </TableCell>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                padding={column.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === column.id ? order : false}>
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const {numSelected, classes} = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subheading">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography id="tableTitle">
                        Liste des professionnels
                    </Typography>
                )}
            </div>
            <div className={classes.spacer}/>
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="Filter list">
                            <FilterListIcon/>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    sendButton: {
        color: "red"
    },
});

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'id',
            selected: [],
            page: 0,
            rowsPerPage: 5,
            proData: ''
        };
    }

    componentDidMount() {
        this.setState({proData: this.props.proDatas && this.props.proDatas});
    }

    componentWillReceiveProps = props => {
        if (props.proDatas !== this.props.proDatas) {
            this.setState({proData: props.proDatas});
        }
    };

    proChecked = (e, checked) => {
        !checked ? this.props.isProChecked(null) : this.props.isProChecked(e.target.value);

        if (!checked) {
            this.state.selected.splice(this.state.selected.indexOf(this.refs[e.target.value].props['data-id']), 1)
        } else if (checked) {
            this.setState({
                selected: [...this.state.selected, this.refs[e.target.value].props['data-id']]
            })

        }
    };

    handleRequestSort = (event, property) => {

        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {

        const {selected} = this.state;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };


    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const {classes, loading, error} = this.props;
        const {order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.proDatas && this.props.proDatas.length - page * rowsPerPage);
        const count =   this.props.proDatas && this.props.proDatas.length;

        if (loading) {
            return <div>Loading ...</div>;
        }

        if (error) {
            return <div>Error ...</div>;
        }

        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar numSelected={selected.length}/>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={this.props.proDatas && this.props.proDatas.length}/>
                        <TableBody>
                            {this.state.proData && this.state.proData
                                .sort(getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i) => {
                                    const isSelected = this.isSelected(n.CompanyID);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => this.handleClick(event, n.CompanyID)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={i}
                                            selected={isSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={this.proChecked}
                                                    checked={isSelected}
                                                    value={n.CompanyName}
                                                    ref={n.CompanyName}
                                                    data-id={n.CompanyID}/>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {n.CompanyName}
                                            </TableCell>
                                            <TableCell>{n.Adresse + ' ' + n.Ville + ' ' + n.Code_postal}</TableCell>
                                            <TableCell>
                                                {n.Notes}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 49 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <TablePagination
                    component="div"
                    count={parseInt(count)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                        'color': 'inherit',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                        'color': 'inherit',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                <div>
                    <SendButton id={this.props.id} idsList={this.state.selected} className={classes.SendButton}/>
                </div>
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    searchedValue: state.searchReducer.search,
    loading: state.datasReducer.loading,
    error: state.datasReducer.error
});

const mapDispatchToProps = (dispatch) => ({
    isProChecked: bindActionCreators(proListCheckBox, dispatch),
    fetchDatasWithName: bindActionCreators(fetchDatasWithName, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnhancedTable));
