import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#303F9F',
            contrastText: "#fff",
        },
        secondary: {
            main: '#303F9F',
        },
        action: {
            active: '#fff',
        },  
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 26,
        title: {
            fontFamily: 'Roboto',
        },
        button: {
            fontSize: "1.2rem"
        },
        useNextVariants: true,
    },
});

export default theme;
