import React, {Component} from 'react';
import UserList from './UserList';
import Grid from '@material-ui/core/Grid';
import Layout from '../common/Layout';
import SearchBox from '../common/SearchBox'

class PageSearch extends Component {

    render() {
        return (
            <div>
                <Layout>
                    {/* <Grid container spacing={24}>
                        <Grid item xs={12} md={12}> */}
                            <SearchBox/>
                            <UserList/>
                        {/* </Grid>
                    </Grid> */}
                </Layout>
            </div>
        )
    }
}

export default PageSearch;
