import React from 'react'
import { connect } from 'react-redux'
import { fetchDatas } from '../../actions/fetch.actions'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Create from '@material-ui/icons/Create'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import Moment from 'react-moment'
import { API_URL } from '../../config'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  },
  tableWrapper: {
    overflowX: 'auto'
  },

  ButtonDisabled: {
    backgroundColor: 'red',
    color: 'white'
  },

  ButtonEnabled: {
    backgroundColor: 'green',
    color: 'white'
  }
})

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  }
})

const columnData = [
  {id: 'Title', numeric: false, disablePadding: true, label: 'Titre'},
  {id: 'CompanyID', numeric: false, disablePadding: false, label: 'Vendeur pro'},
  {id: 'Lastname', numeric: false, disablePadding: false, label: 'Nom'},
  {id: 'Firstname', numeric: false, disablePadding: false, label: 'Prénom'},
  {id: 'FullAddress', numeric: false, disablePadding: false, label: 'Localisation'},
  {id: 'Rating', numeric: false, disablePadding: false, label: 'Note'},
  {id: 'Status', numeric: false, disablePadding: false, label: 'Status'},
  {id: 'Price', numeric: false, disablePadding: false, label: 'Prix'},
  {id: 'Timestamp', numeric: false, disablePadding: false, label: 'Date de Création'},
  {id: 'details', numeric: false, disablePadding: false, label: 'Détails'}
]


class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {order, orderBy} = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {columnData.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}>
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}>
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}

let EnhancedTableToolbar = props => {
  const {numSelected, classes} = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        <Typography id="tableTitle">
          Liste des Parkings
        </Typography>
      </div>
      <div className={classes.spacer}/>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

class AllParking extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      page: 0,
      rowsPerPage: 25,
      ids: []
    }
  }

  componentWillMount() {
    this.props.dispatch(fetchDatas(API_URL + '/parkinggetall.php?all', 'proDatas', 'POST'))
  }

  handleRequestSort = (event, property) => {

    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({order, orderBy})
  }

  handleChangePage = (event, page) => {
    this.setState({page})
  }

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value})
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  desc(a, b, orderBy) {
    if (a[orderBy])
      a[orderBy] = a[orderBy].toLowerCase()
    if (b[orderBy])
      b[orderBy] = b[orderBy].toLowerCase()
    if (orderBy === 'SellerPro') {
      if (a[orderBy] && !b[orderBy])
        return -1
      else
        return 1
    }
    if (orderBy === 'AverageRating') {
      if (b[orderBy].Note < a[orderBy].Note)
        return -1
      if (b[orderBy].Note > a[orderBy].Note)
        return 1
      return 0
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy)
  }

  render() {
    const {order, orderBy, selected, rowsPerPage, page} = this.state
    const {classes, loading, error, proDatas, searchedValue} = this.props
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, proDatas && proDatas.length - page * rowsPerPage)

    if (error) {
      return <div>Error ! {error.message}</div>
    }
    if (loading) {
      return <div>Loading ...</div>
    }
    console.log('on commence')
    console.log(this)
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={proDatas && proDatas.length}/>
            {proDatas && this.stableSort(proDatas, this.getSorting(order, orderBy))
              .filter(
              parking => (
                (parking.Title ? parking.Title.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.Lastname ? parking.Lastname.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.Firstname ? parking.Firstname.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.Rating ? parking.Rating.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.CompanyID ? parking.CompanyID.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.FullAddress ? parking.FullAddress.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.Price ? parking.Price.match(new RegExp(searchedValue, 'i')) : '') ||
                (parking.Timestamp ? parking.Timestamp.match(new RegExp(searchedValue, 'i')) : '')
              ))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n, i) => {
                const isSelected = this.isSelected(n.ParkingID)
                return (
                  <TableRow
                    hover
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={i}
                    selected={isSelected}>
                    <TableCell padding="checkbox">
                    </TableCell>

                    <TableCell scope="row" padding="none">
                      {n.Title}
                    </TableCell>
                    <TableCell>
                      {n.CompanyID ? 'Oui' : 'Non'}
                    </TableCell>
                    <TableCell>{n.Lastname}</TableCell>
                    <TableCell>{n.Firstname}</TableCell>
                    <TableCell>{n.FullAddress}</TableCell>
                    <TableCell>{n.AverageRating.Note ? n.AverageRating.Note : 0} / 5</TableCell>
                    <TableCell>
                      {n.Status == 2 &&
                      <Button variant="contained" className={classes.ButtonDisabled}>
                        Inactif
                      </Button>
                      }
                      {n.Status == 1 &&
                      <Button variant="contained" className={classes.ButtonEnabled}>
                        Actif
                      </Button>
                      }

                    </TableCell>
                    <TableCell>
                      {n.Price}
                    </TableCell>
                    <TableCell>
                      <Moment format="DD/MM/YYYY">{n.Timestamp}</Moment>
                    </TableCell>
                    <TableCell>
                      <IconButton component={Link} to={'/info-parking/' + n.ParkingID}>
                        <Create color='primary'/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}

            <TableBody>
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <TablePagination
          component="div"
          count={proDatas && proDatas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            'color': 'inherit'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            'color': 'inherit'
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
      </Paper>
    )
  }
}

AllParking.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  searchedValue: state.searchReducer.search,
  proDatas: state.datasReducer.proDatas,
  loading: state.datasReducer.loading,
  error: state.datasReducer.error
})

export default withStyles(styles)(connect(mapStateToProps)(AllParking))
