import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {fetchDatasWithName} from "../../actions/fetchWithName.actions";
import {history} from '../../helpers';
import ModalCancel from './ModalCancel';
import ModalActivity from "./ModalActivity";
import ModalTask from "./ModalTask";
import ModalFinish from "./ModalFinish";
import Modal from "@material-ui/core/Modal";
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { API_URL } from "../../config";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        padding: 10,
        Height: '100%',
        flexWrap: 'nowrap',
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        paddingRight: 10
    },
    table1: {
        border: 1,
        borderStyle: 'solid',
        borderColor: 'black',
        width: 400,
    },
    rounded: {
        display: 'block',
        borderRadius: '50%',
        margin: 'auto',
        marginTop: '10px'
    },
    tablerow: {
        display: 'flex',
        flexDirection: 'row',
        borderCollapse: 'separate',
    },
    tablecell: {
        borderBottom: 'none',
        display: 'block',
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        margin: 15,
        width: '100%',
        height: '100%',
    },
    cellmodal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    celltitle: {
        textAlign: 'center',
    },
    displayHide: {
        display: 'none'
    },
    cardcontentpro: {
        maxHeight: '100%',
        display: 'flex',
        overflow: 'auto',
        width: '100%',
        flexWrap: 'nowrap',
    },
    paddingTable: {
        paddingRight: 10
    },
    body: {
        overflowY: 'scroll',
        height: 600,
        maxHeight: 600
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    modal: {
        display: 'flex'
    },
    todelete: {
        marginTop: '10px',
        marginLeft: '10px',
    },
    paperPro: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing.unit * 60,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    edition: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    paperTask: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
});

const taskStatus = [
  {
    value: '0',
    label: 'A faire'
  },
  {
    value: '1',
    label: 'En cours'
  },
  {
    value: '2',
    label: 'Annuler'
},
{
    value: '3',
    label:'Terminer'
}
];

class ProContacted extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: '',
            open: false,
            onPro: false,
            editTask: false,
            editTitle: '',
            editDescription: '',
            editDate: '',
            editStatus: '',
            editUser: '',
        };
        this.deleteInquery = this.deleteInquery.bind(this);
        this.modalEditTask = this.modalEditTask.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
    }

    componentWillMount() {
        const data = new FormData();

        data.append("inqueryid", this.props.id);
        this.props.dispatch(fetchDatasWithName(API_URL + '/company/companygetallfrominqueries.php', 'proContacted', 'POST', data));
    }

    handleChange(value, key) {
        this.setState({[key]: value})
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false, editTask: false });
    }

    onSaveEdit() {
      var data = new FormData();
      data.append("taskid", this.state.editTask);
      data.append("title", this.state.editTitle);
      data.append("taskdescription", this.state.editDescription);
      data.append("adminid", this.state.editUser);
      data.append("duedate", this.state.editDate);
      data.append("status", this.state.editStatus);
      return fetch(API_URL + '/task/taskupdate.php', {
        method: 'POST',
        headers: {'X-ACCESS-TOKEN': JSON.parse(localStorage.getItem('user')).AccessToken},
        body: data
      }).then(response => {
        return response.json();
      }).then(json => {
        this.handleClose();
        window.location.reload();
        return json
      })
    }

    deleteInquery() {
        let user = JSON.parse(localStorage.getItem('user'));
        const data = new FormData();
        var proContacted = this.props.proContacted.companies;
        data.append("inqueryid", this.props.id);

        if (proContacted) {
          proContacted.map(pro => {
            pro.history.map(history => {
              const data2 = new FormData();
              console.log(history)
              data2.append("taskid", history.TasksID);
              fetch(API_URL + '/task/taskdelete.php', {
                  method: 'POST',
                  headers: {'X-ACCESS-TOKEN': user.AccessToken},
                  body: data2
              })
            })
          })
        }
        return fetch(API_URL + '/inquerydelete.php', {
            method: 'POST',
            headers: {'X-ACCESS-TOKEN': user.AccessToken},
            body: data
        }).then(response => {
            return response.json();
        })
        .then(json => {
            history.push("/all-queries");
        })
    }

    modalEditTask() {
      const {classes} = this.props;

      return (
        <div>
          <TextField
          id="outlined-title"
          label="Titre"
          placeholder="Titre"
          value={this.state.editTitle}
          onChange={(e) => {this.handleChange(e.target.value, 'editTitle')}}
          margin="normal"
          className={classes.textField}
          variant="outlined"
          fullWidth/>

          <TextField
          id="outlined-description"
          label="Description"
          value={this.state.editDescription}
          onChange={(e) => {this.handleChange(e.target.value, 'editDescription')}}
          margin="normal"
          className={classes.textField}
          variant="outlined"
          fullWidth
          multiline
          rows="5"/>

          <TextField
          id="outlined-date"
          label="Date d'échéance"
          value={this.state.editDate}
          onChange={(e) => {this.handleChange(e.target.value, 'editDate')}}
          margin="normal"
          type="date"
          className={classes.textField}
          variant="outlined"
          InputLabelProps={{
              shrink: true,
          }}
          fullWidth/>

          <TextField
          id="outlined-status"
          label="Status"
          value={this.state.editStatus}
          onChange={(e) => {this.handleChange(e.target.value, 'editStatus')}}
          margin="normal"
          variant="outlined"
          select
          fullWidth
          className={classes.textField}>
          {taskStatus.map(task => (
            <MenuItem key={task.value} value={task.value}>
            {task.label}
            </MenuItem>
          ))}
          </TextField>

          <TextField
          id="outlined-Admin"
          label="Utilisateur"
          value={this.state.editUser}
          onChange={(e) => {this.handleChange(e.target.value, 'editUser')}}
          margin="normal"
          variant="outlined"
          select
          fullWidth
          className={classes.textField}>
            {this.props.adminInfo && this.props.adminInfo.map(admin => (
              <MenuItem key={admin.AdminID} value={admin.AdminID}>
              {admin.Username}
              </MenuItem>
            ))}
          </TextField>
          <Button onClick={this.handleClose} color="primary">Annuler</Button>
          <Button onClick={this.onSaveEdit} color="primary">Enregistrer</Button>
      </div>
      )
    }

    edit(history) {
      let user = JSON.parse(localStorage.getItem('user'));
      const data = new FormData();

      this.setState({editTask: history.TasksID});
      data.append("tasksid", history.TasksID);
      fetch(API_URL + '/task/taskgetone.php', {
          method: 'POST',
          headers: {'X-ACCESS-TOKEN': user.AccessToken},
          body: data
      }).then(response => {
          return response.json();
      }).then(json => {
        console.log(json)
        if (json.task) {
          this.setState({editTitle: json.task.Title});
          this.setState({editDescription: json.task.TasksDescription});
          this.setState({editDate: json.task.DueDate});
          this.setState({editStatus: json.task.Status});
          this.setState({editUser: json.task.AdminID});
        }
      })
    }

    clear(history) {
      let user = JSON.parse(localStorage.getItem('user'));
      const data = new FormData();
      data.append("historyid", history.HistoryID);
      fetch(API_URL + '/history/companyhistorydelete.php', {
          method: 'POST',
          headers: {'X-ACCESS-TOKEN': user.AccessToken},
          body: data
      })

      if (history.TasksID != 0) {
        const data2 = new FormData();
        data2.append("taskid", history.TasksID);
        fetch(API_URL + '/task/taskdelete.php', {
            method: 'POST',
            headers: {'X-ACCESS-TOKEN': user.AccessToken},
            body: data2
        })
      }
    }

    render() {
        const {classes, error, loading} = this.props;

        if (error) {
            return (<div>Error in pro contacted</div>);
        }

        if (loading || !this.props.proContacted) {
            return <div>Loading</div>
        }

        if (this.props.proContacted) {
            return (
                <div>
                    <div className={classes.modal}>
                    <ModalFinish companies={this.props.proContacted.companies} InqueryID={this.props.id}/>
                        <ModalCancel InqueryID={this.props.id} companies={this.props.proContacted.companies}/>
                        <Button className={classes.todelete} onClick={this.handleClickOpen} variant="contained" color="#d4d4d4">Supprimer la demande</Button>
                        <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">SUPPRIMER</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Etes-vous sûr de vouloir supprimer cette demande ?
                                </DialogContentText>
                            </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">Annuler</Button>
                            <Button onClick={this.deleteInquery} color="primary" autoFocus>Confirmer</Button>
                        </DialogActions>
                    </Dialog>
                    </div>
                <CardContent className={classes.cardcontentpro}>
                    <div className={classes.root}>
                        <div className={classes.table}>
                            {this.props.proContacted && this.props.proContacted.companies && this.props.proContacted.companies.map((n, i) => {
                                return (
                                    <div key={i} className={classes.paddingTable}>
                                        <Table key={i} className={classes.table1}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.celltitle}>
                                                    {n.CompaLogoURL ?
                                                        <img alt="avatar"
                                                                src={API_URL + '/companylogos/' + n.CompaLogoURL}
                                                                width="30" height="30" className={classes.rounded}/>
                                                        :
                                                        <div/>
                                                    }
                                                           <Typography onMouseOver={(e) => {this.setState({"onPro": true})}} onMouseOut={(e) => {this.setState({"onPro": false})}}>{n.CompanyName}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellmodal} align='center'>
                                                        <ModalActivity CompanyID={n.CompanyID} InqueryID={this.props.id}/>
                                                        <ModalTask CompanyID={n.CompanyID} InqueryID={this.props.id}/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <div className={classes.body}>
                                                <TableBody>
                                                    {n.history && n.history.map((n, i) => {
                                                        console.log('detail de la requete')
                                                        console.log(n);
                                                        return (
                                                            <TableRow key={i}>
                                                                <TableCell className={classes.tablecell}>
                                                                    <Paper className={classes.paper} elevation={1}>
                                                                        <div className={classes.edition}>
                                                                          {
                                                                            !n.TasksID ? <div/> :
                                                                            <div>
                                                                              <Edit onClick={() => this.edit(n)}/>
                                                                              <Clear onClick={() => this.clear(n)}/>
                                                                            </div>
                                                                          }
                                                                          <Modal
                                                                            aria-labelledby="simple-modal-title"
                                                                            aria-describedby="simple-modal-description"
                                                                            open={this.state.editTask}
                                                                            onClose={this.handleClose}>
                                                                            <div id="modal" className={classes.paperTask}>
                                                                                <Typography variant="subtitle1" id="simple-modal-description">
                                                                                    {this.state.editTask && this.modalEditTask()}
                                                                                </Typography>
                                                                            </div>
                                                                          </Modal>
                                                                        </div>
                                                                        <Typography>Date : {n.CreatedAt} </Typography>
                                                                        <Typography>Type : {n.HistoryDescription} </Typography>
                                                                        {n.TasksID || n.ActivityID ? (
                                                                            <div>
                                                                                {n.DateHappen && n.Type &&
                                                                                <div>
                                                                                    <Typography>Description : {n.Type}</Typography>
                                                                                    <Typography>Fait le : {n.DateHappen}</Typography>
                                                                                </div>
                                                                                }
                                                                                {n.DueDate && n.Title &&
                                                                                <div>
                                                                                    <Typography>Titre : {n.Title}</Typography>
                                                                                    <Typography>A faire le : {n.DueDate}</Typography>
                                                                                </div>
                                                                                }
                                                                                <Typography>Details : {(n.ActivityID && n.ActivityDescription)} {(n.TasksID && n.TasksDescription)}</Typography>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <Typography>Description : {n.Message}</Typography>
                                                                                {n.MessageText ?
                                                                                    <Typography>Message : {n.MessageText}</Typography>
                                                                                    :
                                                                                    <div/>
                                                                                }
                                                                                {n.InsuranceName ?
                                                                                    <Typography>Société : {n.InsuranceName}</Typography>
                                                                                    :
                                                                                    <div/>
                                                                                }
                                                                                {n.Price ?
                                                                                    <Typography>Prime annuelle : {n.Price} €</Typography>
                                                                                    :
                                                                                    <div/>
                                                                                }
                                                                                {n.Comment ?
                                                                                    <Typography>Commentaire : {n.Comment}</Typography>
                                                                                    :
                                                                                    <div/>
                                                                                }
                                                                                {(n.PDFLink || n.PDFName) ?
                                                                                    <Typography>Pièce jointe : {n.PDFLink ? <a href={API_URL + '/insurance/insurancequotationpdf/' + n.PDFLink} target="_blank" rel="noopener noreferrer">{n.PDFName || "PDF"}</a> : <a href={API_URL + '/quotation/quotationpdf/' + n.PDFName} target="_blank" rel="noopener noreferrer">{n.PDFName}</a>}</Typography>
                                                                                    :
                                                                                    <div/>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Paper>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </div>
                                        </Table>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </CardContent>
                </div>
            );
        }
    }
}

ProContacted.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return ({
        proContacted: state.datasReducer.proContacted,
        loading: state.datasReducer.loading,
        error: state.datasReducer.error,
        adminInfo: state.datasReducer.adminInfo,
    });
}

export default withStyles(styles)(connect(mapStateToProps)(ProContacted));
